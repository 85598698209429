import React, { useState } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import tw from 'twin.macro'
import Layout from "../components/layout"
import PopuVideo from "../components/PopuVideo"
import Button from "../components/styles/Button"


export default function ProductDetail({ pageContext: { node,list },location }) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const styles = {
        solBlock:[
            tw`flex justify-center items-center z-[1] truncate-3-lines`,
            // tw`relative overflow-hidden transition duration-500 ease-linear hover:text-white`,
            // tw`before:content-none before:z-[-1] before:transition-all before:duration-500 before:ease-linear before:absolute before:bottom-0 before:left-0 before:w-0 before:h-0 before:border-0 before:border-t-transparent before:border-r-transparent before:border-b-transparent before:border-l-black`,
            // tw`after:content-none after:z-[-1] after:transition-all after:duration-500 after:ease-linear after:absolute after:top-0 after:right-0 after:w-0 after:h-0 after:border-0 after:border-t-transparent after:border-r-black after:border-b-transparent after:border-l-transparent`,
            // tw`hover:before:border-t-[240px] hover:before:border-l-[240px]`,
            // tw`hover:after:border-r-[240px] hover:after:border-b-[240px]`
        ]
    }
   

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[100px] pb-[140px]">
                <h1 className="text-[40px] leading-snug pb-[105px]">核心优势</h1>
                <div className="grid grid-cols-3 gap-x-[45px] gap-y-[104px] xl:grid-cols-2 md:grid-cols-1">
                {
                    node.advantage.map((n,i)=>{
                        return <div key={i}>
                                    <figure className="h-[330px] overflow-hidden"><GatsbyImage className="h-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.picture.localFile)} alt="product"/></figure>
                                    <div className="text-3xl leading-tight pt-2.5">{n.title}</div>
                                    <p>{n.info}</p>
                                </div>
                    })
                }
                </div>
            </div>
            <div className="bg-light">
                <div className="container mx-auto pt-[152px] pb-[186px]">
                    <h2 className="text-[40px] leading-snug pb-[44px]">解决痛点</h2>
                    <ul className="grid grid-cols-4 gap-[60px] text-2xl xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                    {
                        node.solution.map((n,i)=>{
                            return <li key={i} className="border-[6px] border-black px-[30px] h-[160px] flex items-center"> <span css={styles.solBlock}>{n.text}</span> </li>
                        })
                    }
                        
                    </ul>
                </div>
            </div>
   
                <div className="flex items-start justify-between container mx-auto py-[100px] xl:flex-col">
                    <h2 className="text-[40px] leading-snug xl:mb-10">详情<br className="xl:hidden"/>标准配置</h2>
                    <div className="w-[944px] xl:w-full">
                    {
                        node.configuration.map((n,i)=>{
                            return <div key={i}>
                                <div className="font-medium border-b-[2px] border-black pb-2">{n.title}</div>
                                <div className="py-[6px] mb-7">
                                {
                                    n.info.map((e,k)=>{
                                        return  <ul key={k} className="grid grid-cols-2 px-4 mb-[6px]">
                                                    <li>{e.title}</li>
                                                    <li>{e.info}</li>
                                                </ul>
                                    })
                                }
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="max-w-[1672px] w-full px-4 mx-auto h-[920px] relative">
                    <PopuVideo video={node.video.link} bg={node.video.background.localFile}/>
                </div>
                <div className="container mx-auto pt-[100px] pb-[140px] sm:pb-24">
                    <h3 className="text-[40px] leading-snug pb-[30px]">同系列产品</h3>
                    <div className="grid grid-cols-3 gap-x-[45px] gap-y-[100px] text-center xl:grid-cols-2 md:grid-cols-1">
                        {
                            list.map((n,i) => {
                                if(n.product_category.id == node.product_category.id){
                                    return (
                                        <div key={i}>
                                            <figure className="h-[330px] overflow-hidden"><img className="h-full w-full object-cover bg-light transform transition-all duration-1000 ease-linear hover:scale-110" src={`https://admin.cnsotto.com${n.seo.shareImage.url}`} alt="product"/></figure>
                                            <div className="text-3xl pb-[30px] pt-5">{n.title}</div>
                                            <Button variant="black" className="mx-auto" href={n.url}>了解更多</Button>
                                            
                                        </div>)
                                }
                                
                            })
                        }
                    </div>
                </div>
        </Layout>
    )
}

